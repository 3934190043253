import '../../../theme/datepicker.css'

import styled from '@emotion/styled'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { de, enUS, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import NumericInput from 'react-numeric-input'

registerLocale('de', de)
registerLocale('en', enUS)
registerLocale('it', it)

const DATE_OPTIONS = {
  year: '2-digit',
  month: '2-digit',
  day: 'numeric',
} as any

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  IBEID = 1,
  languageCode,
  languagePrefix,
}: Props) {
  const [linkerParam, setlinkerParam] = useState()

  /*
   * Form data
   */
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState<any>(
    new Date(new Date().setDate(startDate.getDate() + 1)),
  )

  const guestsWrap = useRef(null)
  const [guestsWrapStatus, setGuestsWrapStatus] = useState(false)

  const [guestsTotal, setGuestsTotal] = useState(0)
  const [adultsValue, setAdultsValue] = useState(2)
  const [child611Value, setChild611Value] = useState(0)
  const [child25Value, setChild25Value] = useState(0)
  const [baby01Value, setBaby01Value] = useState(0)

  const adultsLabel = useVocabularyData('adults', languageCode)
  const child611Label = useVocabularyData('child611', languageCode)
  const child25Label = useVocabularyData('child25', languageCode)
  const baby01Label = useVocabularyData('baby01', languageCode)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (guestsWrap.current && !guestsWrap.current.contains(e.target)) {
      setGuestsWrapStatus(false)
    } else {
      if (guestsWrapStatus && e.target.classList.contains('guests-button')) {
        setGuestsWrapStatus(false)
      } else {
        setGuestsWrapStatus(true)
      }
    }
  }

  useEffect(() => {
    setGuestsTotal(adultsValue + child611Value + child25Value + baby01Value)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [guestsWrapStatus, adultsValue, child611Value, child25Value, baby01Value])

  return (
    <Container dial={5}>
      <Title>
        <Rumble />
        {useVocabularyData('ibe-title', languageCode)}
      </Title>

      <Form
        action="https://be.bookingexpert.it/book/simple/step1"
        target="_blank"
      >
        <input type="hidden" name="hotel" value={IBEID} />
        <input type="hidden" name="lang" value={languagePrefix} />

        <Field className="picker-field">
          <Label>{useVocabularyData('arrival', languageCode)}</Label>
          <Picker>
            {startDate?.toLocaleDateString(languageCode, DATE_OPTIONS)}
          </Picker>
          <DatePicker
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation={true}
            locale={languagePrefix || 'it'}
            onChange={(date) => {
              if (date) {
                setStartDate(date)
                setEndDate(undefined)
              }
            }}
            onFocus={(e) => (e.target.readOnly = true)}
            minDate={new Date()}
            name="checkin"
            selected={startDate}
          />
        </Field>
        <Field className="picker-field">
          <Label>{useVocabularyData('departure', languageCode)}</Label>
          <Picker>
            {endDate?.toLocaleDateString(languageCode, DATE_OPTIONS)}
          </Picker>
          <DatePicker
            autoComplete="off"
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation={true}
            locale={languagePrefix || 'it'}
            onChange={(date) => {
              if (date) {
                setEndDate(date)
              }
            }}
            onFocus={(e) => (e.target.readOnly = true)}
            placeholderText={useVocabularyData('departure', languageCode)}
            minDate={new Date(startDate?.getTime() + 1 * 24 * 60 * 60 * 1000)}
            name="checkout"
            selected={endDate}
          />
        </Field>
        <Guests ref={guestsWrap}>
          <Field>
            <Label>{useVocabularyData('guests', languageCode)}</Label>
            <GuestsValue
              className={`guests-button${guestsWrapStatus ? ' open' : ''}`}
            >
              {guestsTotal}
            </GuestsValue>
          </Field>
          <GuestsWrap
            className={guestsWrapStatus ? 'open' : undefined}
            row
            space="between"
            wrap
          >
            <Field>
              <GuestLabel>{adultsLabel}</GuestLabel>
              <NumericInput
                name="guesttypes[0][17953]"
                min={1}
                max={5}
                style={false}
                value={adultsValue}
                onChange={(num: any) => {
                  setAdultsValue(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{child611Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][17954]"
                min={0}
                max={5}
                style={false}
                value={child611Value}
                onChange={(num: any) => {
                  setChild611Value(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{child25Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][20263]"
                min={0}
                max={5}
                style={false}
                value={child25Value}
                onChange={(num: any) => {
                  setChild25Value(num)
                }}
              />
            </Field>
            <Field>
              <GuestLabel>{baby01Label}</GuestLabel>
              <NumericInput
                name="guesttypes[0][17955]"
                min={0}
                max={5}
                style={false}
                value={baby01Value}
                onChange={(num: any) => {
                  setBaby01Value(num)
                }}
              />
            </Field>
          </GuestsWrap>
        </Guests>
        <Field className="coupon-field">
          <Coupon
            type="text"
            name="coupon"
            autoComplete="off"
            placeholder={useVocabularyData('promo-code', languageCode)}
          />
        </Field>
        <Submit
          type="submit"
          name="search"
          value={useVocabularyData('check-availability', languageCode)}
        />
      </Form>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: calc(100% - 9rem);
  text-align: center;

  @media (max-height: 700px) {
    height: 100%;
  }
`

const Title = styled.div`
  max-width: 36.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.5rem;
  margin: 0 auto;
  text-transform: uppercase;

  svg {
    display: block;
    width: auto;
    height: 1.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin: 0 auto 1.25rem;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5rem auto 0;

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.primaryDark};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.secondaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.secondaryLight} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    opacity: 0.3;
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 3.125rem;
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 0;
    input {
      width: 100%;
      background-position: calc(100% - 3.75rem) center;
      font-size: 0;
    }
  }
  .react-numeric-input {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    input {
      width: 2.5rem;
      caret-color: transparent;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin-left: auto;
      pointer-events: none;
    }
    b {
      width: 1.875rem;
      height: 1.875rem;
      border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.2)};
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        border-color: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
      &:before,
      &:after {
        content: '';
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        position: absolute;
      }
      &:nth-of-type(1) {
        &:before {
          width: 0.875rem;
          height: 0.125rem;
          top: 0.75rem;
          left: 0.375rem;
        }
        &:after {
          width: 0.125rem;
          height: 0.875rem;
          top: 0.375rem;
          left: 0.75rem;
        }
      }
      &:last-of-type {
        margin-left: 0.375rem;
        &:before {
          width: 0.875rem;
          height: 0.125rem;
          top: 0.75rem;
          left: 0.375rem;
        }
      }
    }
  }
`

const Field = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  position: relative;
  &.picker-field {
    min-width: 19.375rem;
    margin-right: 3.375rem;
    padding-right: 6.25rem;
    &:after {
      content: '';
      width: 0.0625rem;
      height: 2.5rem;
      background: ${({ theme }) => theme.colors.variants.primaryDark};
      position: absolute;
      bottom: 0.375rem;
      right: 0;
    }
  }
  &.coupon-field {
    width: 100%;
    margin-top: 3rem;
  }
`

const Picker = styled.div`
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.125rem;
`

const Guests = styled.div`
  position: relative;
`

const GuestsValue = styled.div`
  min-width: 5.625rem;
  cursor: pointer;
  background: url('/chevron-down.svg') no-repeat right center;
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.125rem;
  &.open {
    background-image: url('/chevron-up.svg');
  }
`

const GuestsWrap = styled(FlexBox)`
  min-width: 18.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  opacity: 0;
  padding-bottom: 1.5rem;
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  text-align: left;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
  }
`

const GuestLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
`

const Label = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.625rem;
  margin-bottom: 0.875rem;
  text-transform: uppercase;
`

const Coupon = styled.input`
  width: 100%;
  max-width: 14.6875rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  height: 2.5rem;
  border-bottom: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  line-height: 1.5rem;
  text-align: left;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    text-transform: uppercase;
  }
  &::-moz-placeholder {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    text-transform: uppercase;
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    text-transform: uppercase;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    text-transform: uppercase;
  }
`

const Submit = styled.input`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.secondaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 1.3125rem;
  margin: 2.5rem auto 0;
  padding: 0 1.875rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.secondaryDark};
  }
`
